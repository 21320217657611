.card-people {
  position: relative;
  img {
    border-radius:20px;
    width:100%;
  }
  .weather-info {
    position: absolute;
    top: 30px;
    right: 24px;
    sup {
      font-size: 18px;
    }
    .location {
      font-size: 20px;
      margin-bottom: 0;
    }
  }
}
.weather-info {
  position: absolute;
  top: 30px;
  right: 24px;
  sup {
    font-size: 18px;
  }
  .location {
    font-size: 20px;
    margin-bottom: 0;
  }
}
.fs-30 {
  font-size: 30px;
}
.chartjs-legend {
  ul {
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
    li {
      @include display-flex;
      @include align-items(center);
      span {
        width: 1.562rem        ;
        height: 0.312rem;
        margin-right: .4rem;
        display: inline-block;
        font-size: $default-font-size;
        border-radius: 3px;
      }
    }
  }
  .rtl & {
    ul {
      padding-right: 0;
      li {
        margin-right: 0;
        margin-left: 8%;
        span {
          margin-right: 0;
          margin-left: 1rem;
        }
      }
    }
  }
  &.analytics-legend {
    ul {
      padding-left: 0;
    }
  }
}